<template>
  <l-map ref="map" v-model:zoom="zoom" :center="[55.7823547, 49.1242266]">
    <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
        :attribution="attribution"
    ></l-tile-layer>
    <l-marker
        v-for="(obj, index) of coords"
        :key="index"
        :lat-lng="obj.coords"
        :icon="iconVar"
    >
      <l-popup>
        <span> {{ obj.center }}</span></l-popup
      >
    </l-marker>
  </l-map>
</template>
<script>
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker, LPopup} from "@vue-leaflet/vue-leaflet";
import {ref, toRef} from "@vue/runtime-core";
import {icon} from "leaflet";
import axios from "axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const cities = toRef(props, "cities");
    const coords = ref([]);
    const zoom = ref(5);
    cities.value.forEach((city) => {
      axios
          .get(
              `${process.env.VUE_APP_API_URL}/partners/OSM?name=${city.name}`
          )
          .then((res) => {
            const cityObj = res.data.coordinates;
            if (cityObj) {
              coords.value.push({
                center: city.center,
                coords: [cityObj.lat, cityObj.lon],
              });
            }
          });
    });

    const iconVar = icon({
      iconUrl: require("@/assets/images/icons/geography-icon.png"),
      iconSize: [34, 34],
      iconAnchor: [17, 34],
    });

    const attribution =
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    return {
      zoom,
      coords,
      attribution,
      iconVar
    };
  },
};
</script>

<style lang="css">
.leaflet-attribution-flag {
  display: none !important;
}
</style>