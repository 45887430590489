<template>
  <div>
    <section class="hero">
      <div class="container">
        <div class="__content">
          <h1 class="hero-title">Партнеры</h1>
          <p class="hero-text">
            Сертифицированные тренинговые центры и эксперты
          </p>
          <div class="hero-action btn-action display--flex">
            <button
              @click="openConsultModal = true"
              type="button"
              class="btn btn-accent"
            >
              Проконсультироваться
            </button>
            <button
              @click="openBecomePartnerModal = true"
              type="button"
              class="btn btn-o"
            >
              Стать партнером
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="index-section s-partners">
      <div class="container">
        <div v-if="geographyData.length > 0" class="map-container">
          <partners-map :cities="geographyData" />
        </div>
        <div class="s-partners--search">
          <input
            v-model="searchName"
            class="form-control"
            placeholder="Поиск по названию компании"
          />
          <button
            class="clear-btn"
            v-if="searchName"
            @click="() => (searchName = null)"
          >
            <img src="@/assets/images/icons/x-icon-b.svg" />
          </button>
        </div>
        <div class="s-partners--params">
          <custom-select
            placeholder="Город"
            @selectParam="
              (newFilter) => {
                filters.city = newFilter;
              }
            "
            :options="citiesOptions"
          />
          <custom-select
            placeholder="Услуга"
            @selectParam="(newFilter) => (filters.services = newFilter)"
            :options="servicesOptions"
          />
          <custom-select
            placeholder="Формат услуги"
            @selectParam="(newFilter) => (filters.formats = newFilter)"
            :options="formatsOptions"
          />
        </div>

        <div class="s-partners--filter btn-action display--flex">
          <input
            name="partners-filter"
            type="radio"
            id="filter-all"
            v-model="filters.type"
            value="Все"
          />
          <label
            :class="`btn ${
              filters.type === 'Все' ? 'btn-accent' : 'btn-o'
            } btn-sm`"
            for="filter-all"
            >{{
              `Все ${filters.type === "Все" ? `(${partners.length})` : ""}`
            }}</label
          >
          <input
            name="partners-filter"
            type="radio"
            id="filter-partners"
            v-model="filters.type"
            value="Тренинговые центры"
          />
          <label
            :class="`btn ${
              filters.type === 'Тренинговые центры' ? 'btn-accent' : 'btn-o'
            } btn-sm`"
            for="filter-partners"
            >Тренинговые центры</label
          >
          <input
            name="partners-filter"
            type="radio"
            id="filter-experts"
            v-model="filters.type"
            value="Эксперты"
          />
          <label
            :class="`btn ${
              filters.type === 'Эксперты' ? 'btn-accent' : 'btn-o'
            } btn-sm`"
            for="filter-experts"
            >Эксперты</label
          >
        </div>

        <div v-if="partners && partners.length">
          <div class="s-partners--grid flex">
            <partner-card
              v-for="partner in displayedPartners"
              :imagePath="partner.image"
              :key="partner.id"
              :city="partner.city?.name"
              :name="partner.title"
              :type="
                partner.type === 'center' ? 'Тренинговый центр' : 'Эксперт'
              "
              :experts="
                partner.type !== 'expert' ? partner.experts?.length : null
              "
              :link="
                partner.type === 'center'
                  ? `/partner/${partner.id}`
                  : `/expert/${partner.id}`
              "
            />
          </div>
        </div>
        <div v-else class="hero-text">Загрузка...</div>
      </div>
    </section>
  </div>

  <pop-up-form
    type="ConsultGeography"
    commentField
    title="Получить консультацию по партнерству"
    v-if="openConsultModal"
    @closeModal="openConsultModal = false"
  ></pop-up-form>
  <pop-up-form
    type="new_partner"
    commentField
    title="Оставить заявку"
    v-if="openBecomePartnerModal"
    @closeModal="openBecomePartnerModal = false"
  ></pop-up-form>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import { useRouter } from "vue-router";
import PartnerCard from "../../components/partners/PartnerCard.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import CustomSelect from "../../components/form-elements/custom-select/CustomSelect.vue";
import PartnersMap from "../../components/partners/PartnersMap.vue";
export default {
  components: { PopUpForm, PartnerCard, CustomSelect, PartnersMap },
  name: "partners-navigator-page",
  setup() {
    const router = useRouter(),
      store = useStore();
    const openConsultModal = ref(false),
      showBlockModal = ref(false),
      openBecomePartnerModal = ref(false);

    const searchName = ref(null);
    const searchNameDelay = ref(false);

    const partners = computed(() => store.state.partners.partners);
    const services = computed(() => store.state.partners.services);
    const formats = computed(() => store.state.partners.formats);
    const cities = computed(() => store.state.partners.cities);

    const geographyData = ref([]);

    const displayedPartners = ref([]);

    const closeBlockModal = () => {
      router.push("/");
    };

    const servicesOptions = ref([]),
      citiesOptions = ref([]),
      formatsOptions = ref([]);

    const filters = reactive({
      city: [],
      services: [],
      formats: [],
      type: "Все",
    });

    onBeforeMount(() => {
      store.dispatch("partners/getPartners").then(() => {
        displayedPartners.value = partners.value;
        geographyData.value = partners.value.map((partner) => {
          return { name: partner.city.name, center: partner.title };
        });
      });
      store.dispatch("partners/getServices").then(() => {
        servicesOptions.value = Object.entries(services.value).map(
          ([key, value]) => ({ id: key, name: value })
        );
      });
      store.dispatch("partners/getFormats").then(() => {
        formatsOptions.value = Object.entries(formats.value).map(
          ([key, value]) => ({ id: key, name: value })
        );
      });
      store.dispatch("partners/getCities").then(() => {
        citiesOptions.value = cities.value.map((city) => ({
          id: city.id,
          name: city.name,
        }));
      });
    });

    watch(searchName, () => {
      if (searchName.value && !searchNameDelay.value) {
        searchNameDelay.value = true;
        setTimeout(() => {
          searchNameDelay.value = false;
          filterByName();
          applyFilters();
        }, 500);
        return;
      }
      if (!searchName.value) {
        setWithFilter();
      }
    });

    const filterByName = () => {
      if (searchName.value) {
        const search = searchName.value.toLowerCase();
        displayedPartners.value = [...partners.value].filter((partner) =>
          partner.title.toLowerCase().includes(search)
        );
      }
    };

    const setWithFilter = () => {
      if (filters.type === "Все") {
        displayedPartners.value = partners.value;
        return;
      }
      if (filters.type === "Тренинговые центры") {
        displayedPartners.value = partners.value.filter(
          (partner) => partner.type === "center"
        );
        return;
      }
      displayedPartners.value = partners.value.filter(
        (partner) => partner.type === "expert"
      );
    };

    const applyFilters = () => {
      displayedPartners.value = displayedPartners.value.filter((partner) => {
        if (
          filters.city.length &&
          !filters.city.includes(String(partner.city?.id))
        )
          return false;
        if (!filters.formats.length && !filters.services.length) return true;
        let formatFlag = false,
          serviceFlag = false;
        if (!filters.formats.length) formatFlag = true;
        else {
          for (let i = 0; i < partner.job_formats.length; i++) {
            if (filters.formats.includes(partner.job_formats[i])) {
              formatFlag = true;
              break;
            }
          }
        }
        if (!filters.services.length) serviceFlag = true;
        else {
          for (let i = 0; i < partner.services.length; i++) {
            if (filters.services.includes(partner.services[i])) {
              serviceFlag = true;
              break;
            }
          }
        }
        return formatFlag && serviceFlag;
      });
    };

    watch(filters, () => {
      setWithFilter();
      applyFilters();
      filterByName();
    });

    return {
      openConsultModal,
      closeBlockModal,
      showBlockModal,
      openBecomePartnerModal,
      partners,
      filters,
      displayedPartners,
      geographyData,

      servicesOptions,
      citiesOptions,
      formatsOptions,

      applyFilters,

      searchName,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/partners";

.s-partners--filter {
  margin-top: 35px;

  input {
    display: none;
  }
}

.s-partners--filter .btn {
  margin: 0 8px 8px 0;
}

.s-partners--filter .btn + .btn {
  margin-left: 0;
}

.s-partners--params {
  position: relative;
  display: flex;
  z-index: 9;
  gap: 10px;
  flex-wrap: wrap;
}

.popup-container {
  margin-right: 35px;

  .popup-title {
    margin-bottom: 30px;
    font-size: 39px;
    line-height: 51px;
    padding-right: 5px;

    @media (max-width: 767px) {
      .popup-title {
        font-size: 36px;
        line-height: 43px;
      }
    }
  }

  .popup-text {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    margin: 5px 5px 10px 0;
    display: inline-block;
  }

  .btn-l-accent {
    padding: 2px 0 0 0;
    margin: 9px 0 10px 0;
  }

  .btn-l-accent:hover {
    border-color: transparent;
  }
}
.map-container {
  max-width: 1108px;
  height: 410px;
  margin-bottom: 25px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 5;
  position: relative;
}

@media (max-width: 767px) {
  .btn-action .btn + .btn {
    margin-top: 0;
    margin-left: 10px;
  }
}

@media (max-width: 465px) {
  .btn-action .btn + .btn {
    margin-top: 12px;
    margin-left: 0;
  }
}
</style>